import React, { useEffect, useState, createRef, useRef } from "react";
//import axios from "axios";
import * as tiltify from "tiltify-lib";
import { Header, Footer, Hero, ProgressBar, Leaderboard } from "../layout";

import * as images from "../../images/";
import Scoreboard from "../Scoreboard/Scoreboard";

//custom call to get user_leaderboards
// async function GetTeamLeaderboard(id, limit = Number.limit_VALUE) {
//   return await tiltify.GETV5_Array(
//     `public/team_campaigns/${id}/user_leaderboards?limit=${limit}`
//   );
// }

const Relay = () => {
  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const fundraisingRef = createRef();
  const prizesRef = createRef();
  const rewardsRef = createRef();
  const waysRef = createRef();
  const tipsRef = createRef();

  const domRef = useRef();

  const [raised, setRaised] = useState(0);
  const [goal, setGoal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [progress, setProgress] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [filterLeaderboard, setFilterLeaderboard] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [match, setMatch] = useState(true);
  // const [challenge, setChallenge] = useState(true);
  const [daf, setDAF] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    //console.log("TEAM_CAMPAIGN", process.env.REACT_APP_TEAM_CAMPAIGN);
    async function GetData() {
      const c = await tiltify.GetCampaign(
        process.env.REACT_APP_TEAM_CAMPAIGN,
        true
      );
      setRaised(Number(c.total_amount_raised.value));
      // setRaised(40000);
      setGoal(Number(c.goal.value.replace(/\.00$/, "")));
      const m = await tiltify.GetMilestones(
        process.env.REACT_APP_TEAM_CAMPAIGN,
        true
      );
      setMilestones(m);

      const l = await tiltify.GetTeamUserLeaderboard(
        process.env.REACT_APP_TEAM_CAMPAIGN,
        52
      );
      setLeaderboard(l);
    }
    GetData();
    /* axios
      .get("https://k6fxzqhjb5.execute-api.us-east-1.amazonaws.com/api")
      .then((response) => {
        console.log("All the data?", response);
        setRaised(Number(response.data.campaigndata.total_amount_raised.value));
        setGoal(
          Number(response.data.campaigndata.goal.value.replace(/\.00$/, ""))
        );
        setLeaderboard(response.data.leaderboard);
        setMilestones(response.data.milestones);
      })
      .catch((err) => {
        console.log("ERROR", err);
      }); */
  }, []);

  useEffect(() => {
    setPercent(Number(100 * (Number(raised) / Number(goal))));
    setProgress(percent > 100 ? 100 : percent);

    setFilterLeaderboard(leaderboard.filter((word) => word.name !== "Relay"));
  }, [goal, percent, raised, leaderboard]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((element) => {
        if (element.isIntersecting === true) {
          setVisible(true);
          observer.unobserve(domRef.current);
        }
      });
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <div className="relay">
      <div className="overlay_lp__top">
        <Header type="normal" link="/" />
        <Hero>
          <div className="hero_new__main">
            <h1>Relay for St.&nbsp;Jude</h1>
            <p>
              Join Relay and St.&nbsp;Jude this September for Childhood Cancer
              Awareness Month. Together, we can help cure childhood cancer. Our
              cause unites all!
            </p>
            <div className="button_wrapper">
              {/* <a
                href="https://donate.tiltify.com/@relay-fm/relay-fm"
                target="_blank"
                rel="noreferrer"
              >
                <span className="cta-primary">Donate Now</span>
              </a> */}
              {/* <button
                className="hero-home__cta"
                onClick={() => {
                  scroll(fundraisingRef);
                }}
              >
                <span className="cta-secondary">Register to Fundraise</span>
              </button> */}
              <div>
                <a
                  href="https://donate.tiltify.com/0220c3fa-fc0e-4ae5-9dd9-174c956d5d1c/incentives"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="cta-primary">Donate Now</span>
                </a>
              </div>
              <div>
                <button
                  className="hero-cta"
                  onClick={() => {
                    scroll(fundraisingRef);
                  }}
                >
                  <span className="cta-secondary">Register to Fundraise</span>
                </button>
              </div>
            </div>
          </div>
          <div className="hero_new__logo">
            <img src={images.relayLogo} alt="Relay logo" />
          </div>
          {/* <div className="hero__content__box">
            <h1>Relay FM for St.&nbsp;Jude</h1>
            <p>
              During the month of September, Relay rallied support for the
              lifesaving mission of St.&nbsp;Jude Children's Research Hospital®
              in honor of Childhood Cancer Awareness Month.
            </p>

            {/* <p>
              <strong>The campaign is now closed.</strong>
            </p>
            <a
              href="https://donate.tiltify.com/@relay-fm/relay-fm"
              target="_blank"
              rel="noreferrer"
            >
              <span className="cta-primary">Donate Now</span>
            </a>
            <button
              className="hero-home__cta"
              onClick={() => {
                scroll(fundraisingRef);
              }}
            >
              <span className="cta-secondary">Register to Fundraise</span>
            </button>
            </button>
          </div>
          <div className="hero__content__logo">
            <img src={images.relayLogo} />
          </div> */}
        </Hero>
      </div>
      <ProgressBar
        raised={raised}
        goal={goal}
        progress={progress}
        milestones={milestones}
      />
      <div className="steps_wrapper">
        <section className="inner-row gutters constrain overlay_lp__content">
          <div className="col-6 opening">
            <p>
              Since 2019, the{" "}
              <a href="https://www.relay.fm/about">Relay Community</a> has
              raised $2.9M for St. Jude. Every child deserves to live their best
              life and celebrate every moment, free from the fear of cancer and
              other catastrophic diseases.
            </p>
            <p>
              If you agree, join Relay and St. Jude this September for Childhood
              Cancer Awareness Month. Our cause unites all!
            </p>

            {/* <p>
              Throughout the month, Relay co-founders will be battle for the
              title of co-founder champion by competing in various challenges.
              Even as the competition heats up, we rally behind a cause that
              truly unites us all: Finding cures. Saving children.®
            </p> */}

            {/* <p>
              <strong>Ways to help:</strong>
            </p> */}
            {/* <p>
              <a
                href="https://donate.tiltify.com/@relay-fm/relay-fm"
                target="_blank"
                rel="noreferrer"
              >
                Donate to the event
              </a>{" "}
              and{" "}
              <span
                onClick={() => {
                  scroll(rewardsRef);
                }}
              >
                earn donation rewards
              </span>{" "}
              for gifts of $60+{" "}
            </p> */}
            {/* <ul>
              <li>
                <span
                  onClick={() => {
                    scroll(waysRef);
                  }}
                >
                  Request an employer-matching gift via Tiltify
                </span>
              </li>
              <li>
                Start a $500+{" "}
                <span
                  onClick={() => {
                    scroll(waysRef);
                  }}
                >
                  matching donation challenge
                </span>
              </li>
              <li>
                Donate with a{" "}
                <span
                  onClick={() => {
                    scroll(waysRef);
                  }}
                >
                  donor-advised fund
                </span>
              </li>
            </ul> */}
            {/* <p>
              <span
                onClick={() => {
                  scroll(fundraisingRef);
                }}
              >
                Start a campaign and fundraise
              </span>{" "}
              to earn prizes through September 30
            </p>
            <ul>
              <li>
                Review our{" "}
                <span
                  onClick={() => {
                    scroll(tipsRef);
                  }}
                >
                  tips and best practices
                </span>{" "}
                to jumpstart your fundraising
              </li>
            </ul> */}

            {/* <p>
                As always, we invite you to <a href="https://tiltify.com/@relay-fm/relay-fm-for-st-jude-2022">make a donation</a> to
                the Relay FM Podcast-A-Thon. Donors who make an individual gift
                of $60+ or $100+ can receive one-of-a-kind Relay&nbsp;FM
                incentives. See if your employer also{" "}
                <a href="https://www.stjude.org/get-involved/workplace-giving/matching-gifts.html">
                  offers a matching gift program
                </a>
                . Send a confirmation of your matching gift request to{" "}
                <a href="mailto:matches@relay.fm">matches@relay.fm</a>.
              </p>
              <p>
                But if you want to get more hands on and earn some exclusive
                Relay FM merch, you can now{" "}
                <a href="https://tiltify.com/st-jude-children-s-research-hospital/relay-fm-for-st-jude-2022/start/">start your own fundraising campaign</a> under our
                Relay&nbsp;FM Communiy Challenge!
              </p> */}
          </div>
          <div className="col-6 scoreboard-home">
            {/* <img src={images.relayFeature} alt="Stephen and Myke from Relay" /> */}
            <Scoreboard size="page" />
            <p>
              <em>
                Throughout the month, Relay co-founders will be battle for the
                title of co-founder champion by competing in various challenges.
                Even as the competition heats up, we rally behind a cause that
                truly unites us all: Finding cures. Saving children.®
              </em>
            </p>
          </div>
        </section>
      </div>
      <div className="donation_wrapper">
        <div
          // className="prizes_wrapper"
          className={`prizes_wrapper  ${isVisible ? "is-visible" : ""}`}
          // ref={domRef}
        >
          <section
            className="inner-row gutters constraing overlay_lp__content prizes"
            ref={rewardsRef}
          >
            <div className="col-12">
              <div className="col-12">
                <h2>Donation Rewards</h2>
              </div>
              <p>
                Make sure to claim these limited edition rewards with your
                donation.
              </p>
            </div>
            <div className="col-2">&nbsp;</div>
            <div className="col-4 rewards_wrapper">
              <div className="prizes__amount">$60+ Donation</div>
              <img
                src={images.relayDigital}
                className="item"
                alt="Relay Wallpapers and MacOS Screensaver"
                ref={domRef}
              />
              <span className="title">
                Relay Wallpapers + macOS Screensaver
              </span>
              <span>
                Make an individual gift of $60 or more to earn exclusive
                wallpapers and macOS screensaver.
              </span>
            </div>
            <div className="col-4 rewards_wrapper">
              <div className="prizes__amount">$100+ Donation</div>
              <img
                src={images.relayStickers}
                className="item"
                alt="Relay Sticker Bundle"
                ref={domRef}
              />
              <span className="title">Sticker Pack + Digital Bundle</span>
              <span>
                Make an individual gift of $100 or more to earn the sticker pack
                plus the entire digital bundle.
              </span>
            </div>
            <div className="col-2">&nbsp;</div>
          </section>
        </div>

        <section
          className="inner-row gutters constraing overlay_lp__content ways"
          ref={waysRef}
        >
          <div className="col-12">
            <h2>New ways to give</h2>
            <p>
              Below are new ways to make your dollar count even more for the
              kids of St.&nbsp;Jude
            </p>
          </div>
          {/* <div className="col-2"></div>
          <div className="col-8">
            <ol>
              <li
                className={challenge ? "nav-item expanded" : "nav-item"}
                onClick={() => {
                  setChallenge(!challenge);
                }}
              >
                <span>
                  <span className="title">Matching Donation Challenge</span>

                  <span className="cta-text">
                    {challenge ? "Collapse" : "Expand Info"}
                  </span>
                </span>
              </li>
              <li className={challenge ? "item expanded" : "item"}>
                <p>
                  Are you planning to donate $500 or more? Why not inspire a
                  train of donations by issuing a donation match challenge! You
                  can now match donations up to the total of your match
                  challenge through Tiltify.
                </p>
                <ul>
                  <li>
                    <strong>Step 1: </strong>When donating, click “Create a
                    matching donation” on the right side of the donation details
                    page.
                  </li>
                  <li>
                    <strong>Step 2: </strong>Fill out your personal information
                    then select your donation challenge amount ($500+) and
                    duration (1hr to 5 days).
                  </li>
                  <li>
                    <strong>Step 3: </strong>Once live, donors will see matches
                    applied to their donations on the campaign page until the
                    match total is met or time expires! Once the challenge is
                    complete, the matching donor's donation will appear on the
                    campaign page.
                  </li>
                </ul>
                <p>
                  Need Help? <a href="mailto:stjude@relay.fm">Contact us</a>.
                </p>
              </li>
            </ol>
          </div>
          <div className="col-2"></div> */}
          <div className="col-2"></div>
          <div className="col-8">
            {/* <p>
        <strong>New ways to give:</strong>
      </p> */}

            <ol>
              <li
                className={match ? "nav-item expanded" : "nav-item"}
                onClick={() => {
                  setMatch(!match);
                }}
              >
                <span>
                  <span className="title">Employer-Matching Gifts</span>
                  <span className="cta-text">
                    {match ? "Collapse" : "Expand Info"}
                  </span>
                </span>
              </li>
              <li className={match ? "item expanded" : "item"}>
                <p>
                  Did you know, thousands of companies match their employees'
                  donations to St.&nbsp;Jude? You can now request to have your
                  donation matched and have the employer-match applied to the
                  Tiltify campaign!
                </p>
                <ul>
                  <li>
                    <strong>Step 1: </strong>Make a personal donation to the
                    Tiltify campaign.
                  </li>
                  <li>
                    <strong>Step 2: </strong>On the donation summary screen,
                    click “Search employers” to see if your employer has a
                    matching gift program. If so, you'll find details specific
                    to your company's program.
                  </li>
                  <li>
                    <strong>Step 3: </strong>After selecting your employer, you
                    will receive an email with instructions on how to submit
                    your matching gift request. After submitting your request
                    and clicking the green button in your email, the matching
                    gift will be credited to the campaign total within 24 hours.
                  </li>
                  <li>
                    <strong>Note: </strong>Employer-matching gifts count towards
                    the campaign total but are not eligible for donation rewards
                    offered. Didn't request your match when you donated?
                  </li>
                </ul>
                <p>
                  <em>
                    If you forgot to search your employer at the time of
                    donation or your company requires you to donate through your
                    company portal,{" "}
                    <a href="mailto:stjude@relay.fm">contact us</a> and we will
                    assist in manually crediting for a requested match.{" "}
                  </em>
                </p>
                <p>
                  Didn't receive the email?{" "}
                  <a href="mailto:stjude@relay.fm">Contact us</a>.
                </p>
              </li>
            </ol>
          </div>
          <div className="col-2"></div>
          <div className="col-2"></div>
          <div className="col-8">
            {/* <p>
        <strong>New ways to give:</strong>
      </p> */}

            <ol>
              <li
                className={daf ? "nav-item expanded" : "nav-item"}
                onClick={() => {
                  setDAF(!daf);
                }}
              >
                <span>
                  <span className="title">Donor-Advised Fund Giving</span>

                  <span className="cta-text">
                    {daf ? "Collapse" : "Expand Info"}
                  </span>
                </span>
              </li>
              <li className={daf ? "item expanded" : "item"}>
                <p>
                  You may now use your donor-advised fund to support
                  St.&nbsp;Jude and credit your gift to the Tiltify campaign!
                </p>
                <ul>
                  <li>
                    <strong>Step 1: </strong>Recommend a gift by contacting your
                    fund administrator, using your fund administrator app, or by
                    granting online.
                  </li>
                  <li>
                    {/* <strong>Step 2: </strong>Request a donor-advised fund
                    distribution to St.&nbsp;Jude from your financial
                    institution. Save the confirmation page for your requested
                    distribution. */}
                    <strong>Step 2: </strong>Save the confirmation page for your
                    requested distribution.
                  </li>
                  <li>
                    <strong>Step 3: </strong>Then visit{" "}
                    <a
                      href="https://www.stjude.org/tiltifycredit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      stjude.org/TiltifyCredit
                    </a>{" "}
                    to fill out the form to credit your requested distribution
                    to this Tiltify campaign.
                  </li>
                  <li>
                    <strong>Note: </strong>DAF gifts count towards the campaign
                    total but are not eligible for donation rewards offered.
                  </li>
                </ul>
                <p>
                  Need Help? <a href="mailto:stjude@relay.fm">Contact us</a>.
                </p>
              </li>
            </ol>
          </div>
          <div className="col-2"></div>
        </section>
      </div>
      <section
        className="inner-row gutters constraing overlay_lp__content c_challenge"
        ref={fundraisingRef}
      >
        <div className="col-12">
          <h2>Join the Relay Community Challenge</h2>
          <p>
            Create your own fundraiser to join Relay in raising money for St.
            Jude during the month of September
          </p>
        </div>
        <div className="col-3 steps">
          <img alt="signup" src={images.mouseIcon} />
          <p className="steps__header">Sign Up</p>
          <p className="steps__content">
            Get started by signing up and creating your campaign through
            Tiltify.
          </p>
        </div>
        <div className="col-3 steps">
          <img alt="participate" src={images.starIcon} />
          <p className="steps__header">Participate</p>
          <p className="steps__content">
            Fundraise to raise money for St.&nbsp;Jude
          </p>
        </div>
        <div className="col-3 steps">
          <img alt="share" src={images.shareIcon} />
          <p className="steps__header">Share</p>
          <p className="steps__content">
            Ask your family to donate and share your campaign. Post your
            campaign on social media and tag your friends.
          </p>
        </div>
        <div className="col-3 steps">
          <img alt="rewards" src={images.giftIcon} />
          <p className="steps__header">Get Rewards</p>
          <p className="steps__content">
            Keep fundraising for the kids of St.&nbsp;Jude to unlock exclusive
            merchandise.
          </p>
        </div>
        <div className="col-12">
          <a
            href="https://tiltify.com/+relay-for-st-jude/relay-for-st-jude-2024/start/cause-summary"
            rel="noreferrer"
            target="_blank"
          >
            <span className="cta-primary">Sign Up and Fundraise</span>
          </a>
        </div>
        <div className="col-12">
          <div className="inner-row gutters constraing instructions">
            <div className="col-5">
              <img
                src={images.relayImage}
                alt="Stephen shooting off confetti"
              />
            </div>
            <div className="col-7" ref={tipsRef}>
              <h2>Fundraising Tips & Resources</h2>
              <p>
                While fundraising for St.&nbsp;Jude kids, HAVE FUN.
                Personalizing your fundraising campaign to your interests will
                make your fundraising experience more engaging for you and your
                donors! Here are some best practices and thought starters to get
                you going!
              </p>
              <ul>
                <li>
                  Add our exclusive{" "}
                  <span
                    onClick={() => {
                      scroll(rewardsRef);
                    }}
                  >
                    Relay donation rewards
                  </span>{" "}
                  for your donors to redeem.{" "}
                  <a
                    href="https://info.tiltify.com/support/solutions/articles/43000662649-adding-incentives-cause-rewards"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Here's how
                  </a>
                  . We will ship them to your donors at the end of the campaign.
                </li>
                <li>
                  Create fun interactive donation rewards (e.g. I'll share who I
                  think your celebrity doppelgänger is for $50+ or I'll add your
                  name to my donor thank you wall for $20+ and update it on
                  social)
                </li>
                <li>
                  Set a milestone goal for your donors to unlock special event
                  (e.g. Host a game night at $500 raised. Or I'll dye my hair at
                  $1,000 raised.)
                </li>
                <li>
                  Create a fun donation poll to let your donor help settle a
                  debate (e.g., Does pineapple belong on pizza? Or what color
                  should I dye my hair?)
                </li>
                <li>
                  When reaching to prospective donors, remind them to use the
                  blue button on the donation summary screen to see if their
                  employer offers a{" "}
                  <span
                    onClick={() => {
                      scroll(waysRef);
                    }}
                  >
                    matching gift program
                  </span>{" "}
                  and have the match credited to your fundraising total.{" "}
                </li>
                <li>
                  Post to your social media frequently with reminders to donate!
                  Don't forget to promote your rewards, milestones, and polls.
                </li>
                <li>
                  Create a list of friends and family to reach out to directly
                  via text, email, DM, etc. Send your fundraising link and share
                  your “why” for fundraising. Share any fun rewards/milestones
                  that may personally interest them!{" "}
                </li>
              </ul>
              <p>
                <strong>
                  Need more fundraising ideas?{" "}
                  <a href="/relay-bestpractices/" target="_blank">
                    Click here
                  </a>
                </strong>
                .{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="incentives_wrapper">
        <section className="inner-row gutters constrain overlay_lp__content prizes">
          <div className="col-12">
            <h2 className="prizes_head">Fundraising Incentives</h2>
            <p className="prizes_desc">
              Earn these limited edition incentives by fundraising alongside
              Relay through September 30.
            </p>
          </div>
          <div className="col-4 rewards_wrapper">
            <div className="prizes__amount">$1+ Raised</div>
            <img src={images.relayCoin} className="item" alt="Relay Coin" />
            <span className="title">Relay Coin</span>
            <span>
              Raise at least $1 and get this one-of-a-kind Relay Challenge coin.
            </span>
          </div>
          <div className="col-4 rewards_wrapper">
            <div className="prizes__amount">$100+ Raised</div>
            <img
              src={images.relayMousePad}
              className="item"
              alt="Relay DeskMat"
            />
            <span className="title">Relay Deskmat</span>
            <span>
              Raise $250 or more for St.&nbsp;Jude and receive this exclusive
              oversized deskmat.
            </span>
          </div>
          <div className="col-4 rewards_wrapper">
            <div className="prizes__amount">$500+ Raised</div>
            <img
              src={images.relayTumblrHat}
              className="item"
              alt="Relay Hat and Tumbler"
            />
            <span className="title">Relay Hat + Tumbler</span>
            <span>
              Raise $500 or more for St.&nbsp;Jude and receive a Relay Tumbler
              and hat.
            </span>
          </div>
          <div className="col-12">
            <a
              href="https://tiltify.com/+relay-for-st-jude/relay-for-st-jude-2024/start/cause-summary"
              rel="noreferrer"
              target="_blank"
            >
              <span className="cta-primary">Sign Up and Fundraise</span>
            </a>
          </div>
        </section>
      </div>
      <section
        className="inner-row gutters constrain overlay_lp__content prizes"
        ref={prizesRef}
      >
        <Leaderboard leaderboard={filterLeaderboard} />
        {/* <div className="col-12">
          <a
            href="https://tiltify.com/+relay-fm/relay-fm-for-st-jude-2023/start"
            rel="noreferrer"
            target="_blank"
          >
            <span className="cta-primary">Sign Up and Fundraise</span>
          </a>
        </div> */}
      </section>
      <div className="patient_wrapper">
        <section className="inner-row gutters constrain overlay_lp__content patient">
          <div className="col-1"></div>
          <div className="col-5 photo">
            <img
              src={images.relayJosiah}
              alt="St. Jude patient Aspen, diagnosed with cancerous tumor"
            />
            <p>
              St.&nbsp;Jude patient Josiah, 15 years old, Tennessee, Glioma
              Astrocytoma
            </p>
          </div>
          <div className="col-5">
            <p>
              At six months of age, Josiah was diagnosed with a cancerous brain
              tumor called a glioma astrocytoma. He underwent surgery at a local
              hospital, but because of the tumor's location in his brain, it
              could not be completely removed.
            </p>

            <p>
              Josiah's parents, Merri and Stephen, obtained a referral to St.
              Jude Children's Research Hospital, where he received 18 rounds of
              chemotherapy to fight the remaining tumor. During treatment,
              Josiah underwent subsequent surgeries, and received physical,
              occupational, and speech therapy.
            </p>

            <p>
              Today, what's left of the tumor is dormant and he participates in
              the St. Jude Life Study. Josiah attends school, church youth
              group, and loves volunteering with younger children. He has a
              younger brother and sister, and the three of them all love
              reading, music, playing basketball, and more.
            </p>
          </div>
          <div className="col-1"></div>
        </section>
      </div>

      {/* <section className="inner-row gutters constrain overlay_lp__content rules">
                    <div className="col-12">
                        <p>&#42;In order to win one of the five (5) trips for two (2) to visit the set for the Game Theory $1,000,000 Challenge Livestream, fundraisers must meet the following qualifications:</p>
                        <ul>
                            <li>Hold the top spot on the overall leaderboard by the end of fundraising on 10/31 OR be one of the top weekly fundraisers (Friday - Thursday) from 10/1 to 10/31</li>
                            <li>Be at least 18 years old, and live in the United States of America</li>
                            <li>Have a valid government issue ID and be able to fly in the United States</li>
                        </ul>
                        <p>If a winner does meet these qualifications, the next eligible candidate in line will be selected</p>
                        <p>Winners will be notified of their status on 11/1</p>
                        <p>If a winner does not respond to notification that they have won the prize in 48 hours, a new winner will be selected</p>
                        <p>Organizers will provide domestic US travel for the winner and a guest that also meets the above requirements, as well as hotel accommodations and transportation while they are in LA for the livestream</p>
                        <p>This prize is non-transferable, and is offered as-is</p>

                    </div>
                </section> */}
      <Footer />
    </div>
  );
};
export default Relay;
