import React, { useEffect, useState, Fragment } from "react";

// import myke from "../../images/myke-heade.png";
// import stephen from "../../images/stephen-head.png";

import mykeFight from "../../images/myke-fight.png";
import stephenFight from "../../images/stephen-fight.png";

const Scoreboard = (props) => {
  const [stephenPoints, setStephenPoints] = useState(0);
  const [mykePoints, setMykePoints] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      fetch(process.env.REACT_APP_APIGATEWAY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ function: "scan" }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          // console.log(
          //   "data 0:",
          //   data.Items[0].id,
          //   data.Items[0].person,
          //   data.Items[0].score,
          //   "data 1:",
          //   data.Items[1].id,
          //   data.Items[1].person,
          //   data.Items[1].score
          // );
          setStephenPoints(data.Items[1].score);
          setMykePoints(data.Items[0].score);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 5000);

    return () => clearInterval(timer); //This is important
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const size = urlParams.get("size");

  return (
    <Fragment>
      {size === "small" || props.size === "page" ? (
        <div
          className={
            size === "small"
              ? "scoreboard-score small"
              : props.size === "page"
              ? "scoreboard-score page"
              : "scoreboard-score big"
          }
        >
          <div className="head_wrapper">
            <div className="stephen">
              {/* <img src={myke} alt="myke head" /> */}
              <p className="page_points">{stephenPoints}</p>
            </div>
            <div className="myke">
              {/* <img src={stephen} alt="stephen head" /> */}
              <p className="page_points">{mykePoints}</p>
            </div>
          </div>
        </div>
      ) : size === "large" ? (
        <div className="scoreboard-score large">
          <div className="glow-box"></div>
          <div className="header">
            <h1>Relay FM</h1>
            <p>Podcastathon 2023</p>
          </div>
          <div
            className={
              "score-wrapper" +
              `${mykePoints >= 100 || stephenPoints >= 100 ? " triple" : ""}`
            }
          >
            <div className="score stephen">
              {/* <p className="name">Myke</p> */}
              <p className="points">{stephenPoints}</p>
            </div>
            <div className="score myke">
              {/* <p className="name">Stephen</p> */}
              <p className="points">{mykePoints}</p>
            </div>
          </div>
          <div className="fight-wrapper">
            <div className="fight myke">
              <img src={mykeFight} alt="myke" />
            </div>
            <div className="verse">VS</div>
            <div className="fight stephen">
              <img src={stephenFight} alt="stephen" />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Scoreboard;
