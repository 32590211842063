import React from "react";
import { Header, Footer, Hero } from "../layout";

import * as images from "../../images/";

const OverlayLP = () => {
  return (
    <div className="relay">
      <div className="overlay_lp__top form">
        <Header type="normal" link="/" />
        <Hero>
          <div className="hero__content__box">
            <h1>Claim your Relay FM Digital Bundle</h1>
          </div>
          {/* <span className="caption">
              Photo taken in 2019 with St. Jude patient Grayson
            </span> */}
        </Hero>
      </div>
      <section className="inner-row gutters constrain overlay_lp__content digital">
        <div className="col-1" />
        <div className="col-10">
          <img src={images.relayDigital} alt="Stephen" />
          <h1>
            Thank you for supporting the lifesaving mission of St.&nbsp;Jude
          </h1>
          <p>Click the button below to claim your Relay FM Digital Bundle</p>
          <a href="https://playlive.experience.stjude.org/2023_RelayFM_Digital_Bundle.zip">
            <span className="cta-primary">Download Your Bundle</span>
          </a>
        </div>
        <div className="col-1" />
      </section>
      <Footer />
    </div>
  );
};

export default OverlayLP;
